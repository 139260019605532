import React from 'react';
import back from '../photo/photo ansart-tp/accueil/site-541196_1920_copie.jpg';


import certificat from "../photo/photo ansart-tp/photocertificat/certificat45001.png"



class Contact extends React.Component {


    render() {
        return (
            <div>
            <div className="qualité">
                <div className="Hautpage" style={{backgroundImage: `url(${back})`}}>
                    <table className="tablehaut">

                        <tr>
                            <th >
                                <div className="Titre">
                                    <br/>


                                    <p>Créée depuis 2005, ANSART TP concentre ses activités dans le secteur des Travaux Publics, du génie civil et des travaux ferroviaires.
                                    </p><br/>
                                    <p  id={"GCF"}> Vous pouvez retrouver tous nos engins ferroviaires sur <a href="https://attirail.fr" target="_blank"> ATTIRAIL.FR</a></p>

                                </div>
                            </th>


                        </tr>

                    </table>
                </div>



                <div className="qualité">

                            <div className="nomcat">
                                <h2> QSE | Politique qualité</h2>
                            </div>

                    <br/>
                    <br/>

                                    <div className="qualitytext">
                            <p>
                                Nous avons toujours cherché à apporter la meilleure organisation (humaine , matérielle et technique) à nos clients pour satisfaire pleinement les exigences dans leurs projets de travaux.<br/>
                                <br/>
                                Le marché d’aujourd’hui et de demain est de plus en plus concurrentiel, et nous demande d ‘optimiser en permanence notre organisation afin de satisfaire les exigences légales et réglementaires, et celles de nos clients.<br/>
                                <br/>

                                Pour réaliser cet objectif volontaire de croissance nous devons constamment être :
                            </p>
                            <li>
                                À l’ écoute de nos clients
                            </li>
                            <li>
                                Adapter nos moyens , méthodes et structures pour maitriser nos coûts et délais                            </li>
                            <li>
                                Élaborer des solutions innovantes
                            </li>
                            <li>
                                Exploiter les idées et compétences de tous
                            </li>


                            <p>
                                Nous sommes persuadés que le déploiement de notre système de management qualité, assurera la pérennité de notre entreprise.Et aussi nous nous engageons à développer cette culture au bénéfice de nos clients ,en nous dotant des moyens nécessaires.
                            </p>
                                    </div>

                </div>
                <div className="nomcat">
                    <h2> Certification 45001 </h2>
                </div>
                <br/>

                <div className="certificat">
                    <img src={certificat} alt="photo_certificat" />
                </div>

                <div className="qualité qualificationsFNTP">

                    <div className="nomcat">
                        <h2> Qualifications FNTP</h2>
                    </div>
                    <div className="qualitytext">

                    <br/>
                    <br/>
                    <br/>
                    <p>Groupe 1 – OUVRAGES D’ART ET OUVRAGES INDUSTRIELS:
                    </p>
                    <li>
                        11 – Ouvrages d’art et de génie civil industriel (béton et acier béton)
                    </li>
                    <li>
                        113 Ouvrages de technicité courante.
                    </li>
                    <p>
                        GROUPE 2 – PRÉPARATION DES SITES ,FONDATIONS ET TERRASSEMENTS :
                    </p>
                    <p>21 – Démolition, abattage:
                    </p>
                    <li>211 Par engin mécanique.
                    </li>

                    <p>23 – Ouvrage en terre, Terrassement:
                    </p>
                    <li>2321 Travaux de terrassement courants en milieu urbain.
                    </li>
                    <li>2342 Couche de forme sur voiries à faible trafic et parkings et plateformes.
                    </li>

                    <p>Groupe 4 – VOIE FERRÉES:
                    </p>

                    <p>
                        41 – Voies ferrées, Construction neuve:
                    </p>
                        <li>413 Installations Terminales Embranchées (ITE), voies de service et gares , réseau privé.
                        </li>

                    <p>
                        42-Régénération de voies ferrées:
                    </p>
                    <li>422 Par autres méthodes (autre que par train de substitution).
                    </li>

                    <p>Groupe 7 – TRAVAUX SPÉCIAUX:
                    </p>

                    <p>71 – Travaux liés à la construction d’ouvrages d’art et d’équipement industriel :
                    </p>
                    <li>713 Sciage-forage.
                    </li>
                    </div>

                </div>


                <div className="qualité qualificationsFNTP">

                    <div className="nomcat">
                        <h2> Qualifications SNCF
                        </h2>
                    </div>
                    <div className="qualitytext">


                    <p> TRAVAUX DE VOIE</p>
                    <li>01011-LC * Remplacement ou consolidation de constituants (traverses,attaches, rails…) et reprises de nivellement ,en voie principale ou aux passages à niveau
                    </li>
                    <li>Ligne classique ou LGV (exploités)
                    </li>
                    <li>01012-LC * Remplacement ou consolidation de constituants sur appareils de voie sur voie principale (sans utilisation d’engin spécialisé lourd)
                    </li>
                    <li>Ligne classique ou ligne à grande vitesse (exploitées)
                    </li>
                    <li>01013-LC* Pose ou substition, voie ballastée sur VP sans dé-garnisseuse ni bourreuse lourde
                    </li>
                    <li>Assainissement lourd sous VP (mise en place de sous-couche) sans train spécialisé
                    </li>
                    <li>LC ou LGV (exploitées)
                    </li>
                    <li>01014* Travaux de voie sur voie de service (ou très faible importance en voie principale)
                    </li>
                    <li>01015* Pose de collecteur de drainage sur voie principale (y compris collecteurs d’entrevoie)
                    </li>
                    <li>Fouilles pour sondages en voie
                    </li>
                    <li>01120* Pose de voie sur dalle (4)
                    </li>
                    <li>01214* Curage mécanique de fossés
                    </li>
                    <li>01215* Inspection télévisuelle et nettoyage par hydrocurage des dispositifs de drainage enterrés
                    </li>
                    <li>01216* Inspection télévisuelle et réactivation des dispositifs de drainage enterrés colmatés (c’est-à-dire dont les fentes sont obturées)
                    </li>
                    <br/>
                    <br/>

                    <p>TRAVAUX DE SIGNALISATION ET DE TELECOMMUNICATIONS
                    </p>
                    <li>03001-01* Travaux de génie civil ,de signalisation et/ou de télécommunications sans risque de déstabilisation de la plateforme (Travaux n’engagent pas les plans P1/P2)
                    </li>
                    <li>03001-02* Travaux de génie civil de signalisation et/ou de Télécommunications avec risques de déstabilisation de la plateforme, massifs engageant les plans P1/P2, traversées sous voies par techniques avec tranchées
                    </li>
                    <li>03201-01* Travaux de signalisations électriques et de raccord de câble y compris réchauf par tous moyens, éclissage et connexion électriques.de rails,pose et dépose signal, potence, trans rigide ou funiculaire-Hors taux de GC
                    </li>
                    <li>03201-02* Boite de jonction pour câbles de signalisation et d’énergie
                    </li>
                    <li>03203-01* Travaux de pose de câbles de Signalisation et/ou d’énergie
                    </li>



</div>

                </div>
            </div>
            </div>
        );
    }

}

export default Contact;
