import React,{useState, useEffect} from "react";
import {Link} from "react-router-dom";
import photo from '../photo/photo ansart-tp/accueil/photocategorie/cropped-logo-ansart.png';
import Button from "./Button";


function Navbar() {
    const [click, setClick] = useState(false);
    const [button, setButton] = useState(true);

    const handleClick = () => setClick(!click);
    const closeMobileMenu = () => setClick(false);

    const showButton = () => {
        if (window.innerWidth <= 960) {
            setButton(false);
        } else {
            setButton(true);
        }
    };
    useEffect(() =>{
        showButton();
    },[])

    window.addEventListener('resize', showButton);

    return (
        <>
            <nav className='navbar'>
                <div className='navbar-container'>
                    <Link to='/' className='navbar-logo' onClick={closeMobileMenu}>

                        <li> <img src={photo} alt='logo' class="fa-typo3"/>  </li>
                        <tr className="titre">
                        <li>Ansart-tp</li>
                        </tr>
                    </Link>
                    <div className='menu-icon' onClick={handleClick}>
                        <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
                    </div>
                    <ul className={click ? 'nav-menu active' : 'nav-menu'}>
                        <li className='nav-item'>
                            <a href="https://ansart-tp.com/#GCF"
                               className='nav-active'
                                  onClick={closeMobileMenu}>
                                GC Ferroviaire
                            </a>
                        </li>
                        <li className='nav-item'>
                            <a href="https://ansart-tp.com/#travaux"
                               className='nav-active'
                               onClick={closeMobileMenu}>
                                Travaux de voies
                            </a>
                        </li>

                        <li className='nav-item'>
                            <a href="https://ansart-tp.com/#VRD"
                               className='nav-active'
                               onClick={closeMobileMenu}>
                                Génie civil & VRD
                            </a>
                        </li>

                        <li className='nav-item'>
                            <a href="https://ansart-tp.com/qualité"
                               className='nav-active'
                               onClick={closeMobileMenu}>
                               QSE
                            </a>
                        </li>
                        <li className='nav-item'>
                            <Link
                                to='/contact'
                                className='nav-active'
                                onClick={closeMobileMenu}
                            >
                                Contact
                            </Link>
                        </li>



                    </ul>




                </div>
            </nav>
        </>
    );
}

export default Navbar;
