import React from 'react';
import { BiMailSend } from "react-icons/bi";

const Header = () => {
    return (
        <nav className="header">
            <ul>
                <div className="BiMailSend">
                    <BiMailSend/>
                </div>
                <p> <a href="mailto:travaux@ansart-tp.com"> travaux@ansart-tp.com</a></p>
                <p> 01.69.40.73.31</p>
            </ul>
        </nav>
    );
};

export default Header;