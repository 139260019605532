import React from 'react';
import axios from 'axios';
//import back from "../photo/fond_image.png";
//import photo1 from "../photo/photo accueil/IMG-20210614-WA0016.jpg";
import AliceCarousel from "react-alice-carousel";
import back from "../photo/photo ansart-tp/accueil/site-541196_1920_copie.jpg";

//import photoc1 from "../photo/Photo_contact/20210505_222033.jpg"
//import photoc2 from "../photo/Photo_contact/IMG-20210614-WA0013.jpg"
//import photoc3 from "../photo/Photo_contact/IMG-20210615-WA0005 - Copie.jpg"



class Contact extends React.Component {

    constructor(props) {
        super(props);
        this.state = {

            name: '',
            email: '',
            subject: '',
            message: '',
            telephone:'',

        }
    }



    submitEmail(e){
        e.preventDefault();
        axios({
            method: "POST",
            url:"https://api.attirail.fr/messageansart",
            data:  this.state
        }).then((response)=>{
            if (response.data.status === 'success'){
                alert("Message envoyé.");
                this.resetForm()
            }else if(response.data.status === 'fail'){
                alert("Message non envoyé, veuillez réessayer.")
            }
        })
    }



    onNameChange(event) {
        this.setState({name: event.target.value})
    }

    onTelephoneChange(event) {
        this.setState({telephone: event.target.value})
    }

    onEmailChange(event) {
        this.setState({email: event.target.value})
    }

    onSubjectChange(event) {
        this.setState({subject: event.target.value})
    }

    onMsgChange(event) {
        this.setState({message: event.target.value})
    }


    resetForm(){
        this.setState({name: '', email: '',subject:'', message: '',telephone:''})
    }


    render() {
        return (
            <div className="nousrejoindre">
                <div className="Hautpage" style={{backgroundImage: `url(${back})`}}>
                    <table className="tablehaut">

                        <tr>
                            <th >
                                <div className="Titre">
                                    <br/>


                                    <p>Créée depuis 2005, ANSART TP concentre ses activités dans le secteur des Travaux Publics, du génie civil et des travaux ferroviaires.
                                    </p><br/>
                                    <p> Vous pouvez retrouver tous nos engins ferroviaires sur <a href="https://attirail.fr" target="_blank"> ATTIRAIL.FR</a></p>
                                </div>
                            </th>



                        </tr>

                    </table>
                    <div id={"GCF"}></div>

                </div>



                <div className="recrutement">
                    <tr>
                        <td>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="section-title">
                                <form id="contact-form" onSubmit={this.submitEmail.bind(this)}
                                      method="POST">
                                    <div className="form-group">
                                        <div className="liste">
                                            <div className="form-group">
                                                <label> Nom :</label>

                                                <input placeholder = "Nom"  id="name" type="text"
                                                       className="form-control" required value={this.state.name}
                                                       onChange={this.onNameChange.bind(this)}/>
                                            </div>
                                            <div className="form-group">
                                                <label> Email :</label>
                                                <input placeholder = "Email"  id="email" type="email"
                                                       className="form-control" aria-describedby="emailHelp"
                                                       required value={this.state.email} onChange=
                                                           {this.onEmailChange.bind(this)}/>
                                            </div>

                                            <div className="form-group">
                                                <label> Objet :</label>

                                        <input placeholder = "Objet"  id="subject" type="text"
                                               className="form-control" required value={this.state.subject}
                                               onChange={this.onSubjectChange.bind(this)}/>
                                            </div>

                                    <div className="form-group">
                                        <label> Téléphone :</label>

                                        <input placeholder = "Telephone"  id="telephone" type="text"
                                               className="form-control" required value={this.state.telephone}
                                               onChange={this.onTelephoneChange.bind(this)}/>
                                    </div>
                                    <div className="form-group">
                                        <label> Message :</label>

                                        <textarea placeholder = "Message"  id="message"
                                              className="form-control" rows="1"
                                              required value={this.state.message}
                                              onChange= {this.onMsgChange.bind(this)}/>
                                    </div>
                                            <div className="envoyer">
                                            <button type="submit" className="button">Envoyer</button>
                                            </div>
                                        </div>

                                    </div>

                                </form>

                            </div>
                        </div>

                    </div>
                        </td>


                        <td>
                        </td>

                    </tr>


                </div>
            </div>
        );
    }

}

export default Contact;
