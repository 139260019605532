
import React, {Component} from 'react';
import back from '../photo/photo ansart-tp/accueil/photocategorie/travaux/fond-image.jpg';


import AliceCarousel from "react-alice-carousel";

import VRD from "../photo/photo ansart-tp/accueil/photocategorie/VRD/VRD.jpg"
import GCFerro1 from "../photo/photo ansart-tp/accueil/photocategorie/GCFerro/GN_ferro.jpg"
import GCFerro2 from "../photo/photo ansart-tp/accueil/photocategorie/GCFerro/bureau.jpg"
import GCFerro3 from "../photo/photo ansart-tp/accueil/photocategorie/GCFerro/gcferro.jpg"
import GCFerro4 from "../photo/photo ansart-tp/accueil/photocategorie/GCFerro/IMG_20191118_104317.jpg"

import tv1 from "../photo/photo ansart-tp/accueil/photocategorie/travaux/Photo 1.jpg"
import tv2 from "../photo/photo ansart-tp/accueil/photocategorie/travaux/travaux_voies.jpg"
import tv3 from "../photo/photo ansart-tp/accueil/photocategorie/travaux/P_20180621_200308.jpg"
import tv4 from "../photo/photo ansart-tp/accueil/photocategorie/travaux/tv.jpg"
import tv5 from "../photo/photo ansart-tp/accueil/photocategorie/travaux/tv2.jpg"
import tv6 from "../photo/photo ansart-tp/accueil/photocategorie/travaux/P_20180711_145818.jpg"

import vrd1 from "../photo/photo ansart-tp/accueil/photocategorie/VRD/gc.jpg"
import vrd2 from "../photo/photo ansart-tp/accueil/photocategorie/VRD/VRD.jpg"
import vrd3 from "../photo/photo ansart-tp/accueil/photocategorie/VRD/Photos 2.jpg"
import vrd4 from "../photo/photo ansart-tp/accueil/photocategorie/VRD/180919 - Ferraillage radier E5.jpg"
import vrd5 from "../photo/photo ansart-tp/accueil/photocategorie/VRD/IMG_2608.jpg"
import vrd6 from "../photo/photo ansart-tp/accueil/photocategorie/VRD/IMG_20190409_131530.jpg"

import 'react-alice-carousel/lib/alice-carousel.css';


class Home extends Component {



    render() {


        return (
            <div>

            <div className="Hautpage" style={{backgroundImage: `url(${back})`}}>
                <table className="tablehaut">

                    <tr>
                        <th >
                    <div className="Titre">
                            <br/>


                            <p>Créée depuis 2005, ANSART TP concentre ses activités dans le secteur des Travaux Publics, du génie civil et des travaux ferroviaires.
                               </p><br/>
                        <p> Vous pouvez retrouver tous nos engins ferroviaires sur <a href="https://attirail.fr" target="_blank"> ATTIRAIL.FR</a></p>
                    </div>
                        </th>



                    </tr>

                </table>
                <div id={"GCF"}/>

            </div>


            <div className="presentation" >

                <tr>
                    <td id="secteur">
                    <p> Nos secteurs d'activités</p>
                    </td>
                </tr>


    <div className="secteurs">

                <div className="secteurnom ">
                    <p> Génie civil ferroviaire</p>
                </div>

        <br/>
        <br/>

<tr>
    <td>
            <div className="photosecteur2" >

                    <img src={GCFerro4} className="sliderimg2" alt="img1"/>


            </div>
    </td>
    <td>
            <div className="descriptionsecteur">
                <p>Par ses multiples compétences et ses différents moyens ANSART TP peut intervenir sur tout ouvrage en milieu ferroviaire.

                    ANSART TP a pour vocation de réaliser tous travaux à moins de 5 mètres d’un rail :</p>
                <li>Travaux de quais</li>
                <li >FBM matage de supports caténaires</li>
                <li>Signalisation</li>
                <li id={"travaux"}>Assainissement</li>
                <li>Passage sous voie</li>
                <li>Puits pour gaine d’ascenseur</li>
                <li>Ouvrages d’art</li>
                <li>Sous-station</li>
                <li >Passerelles</li>

            </div>
    </td>
</tr>
    </div>


                <div className="secteurs" >

                    <div className="secteurnom">
                        <p> Travaux sur voies</p>
                    </div>

                    <br/>
                    <br/>

                    <tr>
                        <td>
                            <div className="photosecteur2" >


                                    <img src={tv5} className="sliderimg2" alt="img2"/>

                            </div>
                        </td>
                        <td>
                            <div className="descriptionsecteur">
                                <p>ANSART TP réalise la mise en place de: </p>
                                <li>Voies neuves</li>
                                <li>Installations terminales embranchées (ITE)</li>
                                <li>Voies de services ou principales</li>
                                <li >Réseaux privés</li>


                            </div>
                        </td>
                    </tr>
                </div>

                <div className="secteurs" id={"VRD"}>

                    <div className="secteurnom">
                        <p> Génie civil et VRD</p>
                    </div>

                    <br/>
                    <br/>

                    <tr>
                        <td>
                            <div className="photosecteur2" >


                                    <img src={vrd1} className="sliderimg2" alt="img2"/>

                            </div>
                        </td>
                        <td>
                            <div className="descriptionsecteur">
                                <p>En voiries et réseaux divers, ANSART-TP peut effectuer des travaux en :</p>
                                <li>Terrassement</li>
                                <li>Tranchées,tranchées blindées</li>
                                <li>Passage fourreaux</li>
                                <li>Aménagement voirie</li>
                                <li>Pose de bordures</li>
                                <li>Pose de pavés</li>


                            </div>
                        </td>
                    </tr>
                </div>


            </div>




            </div>

        );


    }
}






export default Home;
