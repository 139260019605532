import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";


import Header from "./components/header";
import Navbar from "./components/Navbar";
import Home from "./pages/Home"

import Contact from "./pages/contact";
import Qualite from "./pages/qualite"

const Footer = () => (
    <footer >
      <div className="footer">
          <div className="ligne">
            <tr>
              <td id="gauche">
          <li> <a href="mailto:travaux@ansart-tp.com">travaux@ansart-tp.com</a></li>
          <li> 01.69.40.73.31</li>
          </td>

              <td id="droite">
                  <li>

                        <Link to="/qualité">
                  <p id="lien">  Politique qualité & certification</p>
                        </Link></li>
                  <li> 27 Rue de la Longueraie, 91270 Vigneux-sur-Seine </li>
              </td>
            </tr>
          </div>
      </div>
    </footer>
);


class App extends Component {






  render() {

    return (

      <Router>
        <div className="content">
        <Header/>
        <Navbar/>


          <Switch>


              <Route path="/contact/" component={Contact}/>
              <Route path="/qualité/" component={Qualite}/>
              <Route exact path="/" component={Home}/>


          </Switch>
        </div>



        <Footer/>



      </Router>
    );
  }
}

export default (App);

